import React from "react";
import AlbumPromo from "./album-promo/AlbumPromo";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import "./App.css";

// Import your language files
import enTranslation from "./locales/en.json";
import huTranslation from "./locales/hu.json";
import Header from "./header/Header";
import Footer from "./footer/Footer";

// Initialize i18next
i18n.use(initReactI18next).init({
  lng: "en", // Set the default language
  resources: {
    en: {
      translation: enTranslation,
    },
    hu: {
      translation: huTranslation,
    },
  },
});

const App = () => {
  return (
    <div className="app-wrapper">
      <Header />
      <div className="main-content">
        <AlbumPromo />
      </div>
      <Footer />
    </div>
  );
};

export default App;
