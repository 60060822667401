import React from "react";
import "./Header.css";
import LanguageSelector from "../language-selector/LanguageSelector";

const Header = () => {
  return (
    <header id="header">
      <div id="logo">
        <img src={require("./assets/fiery_logo.png")} alt="Band Logo" />
      </div>
      <LanguageSelector />
    </header>
  );
};

export default Header;
